import React, { useState, useRef, useEffect, useCallback } from "react";
import { ChevronLeft, ChevronRight, X, ZoomIn } from "lucide-react";

// Import your images here
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
import image6 from "../assets/image6.png";
import image7 from "../assets/image7.png";
import image8 from "../assets/image8.png";
import image9 from "../assets/image9.png";
import image10 from "../assets/image10.png";
import image11 from "../assets/image11.png";
import image12 from "../assets/image12.png";

// Enhanced Navigation Arrow Component
const NavigationArrow = ({ direction, onClick, disabled }) => {
  const isLeft = direction === "left";
  const Icon = isLeft ? ChevronLeft : ChevronRight;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        absolute top-1/2 -translate-y-1/2 z-30
        ${isLeft ? "left-2 md:left-4" : "right-2 md:right-4"}
        flex items-center justify-center
        w-10 h-10 md:w-12 md:h-12 rounded-full
        bg-white shadow-lg
        transition-all duration-300
        hover:scale-110 hover:bg-gray-50
        focus:outline-none focus:ring-2 focus:ring-blue-500
        disabled:opacity-50 disabled:cursor-not-allowed
        group
      `}
      aria-label={`${isLeft ? "Previous" : "Next"} slide`}
    >
      <Icon className="w-5 h-5 md:w-6 md:h-6 text-gray-900 group-hover:text-blue-600" />
    </button>
  );
};

// Enhanced Modal Component
const ImageModal = ({ image, onClose, index, total, onNext, onPrev }) => {
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
      if (e.key === "ArrowRight") onNext();
      if (e.key === "ArrowLeft") onPrev();
    };
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose, onNext, onPrev]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/90 backdrop-blur-sm mt-16"
      onClick={onClose}
    >
      <div
        className="relative w-full h-full flex items-center justify-center p-4"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-3 rounded-full bg-white 
                     transition-all duration-300 transform hover:scale-110 group"
          aria-label="Close modal"
        >
          <X className="w-6 h-6 text-gray-900 group-hover:text-gray-700" />
        </button>

        {/* Modal Navigation Arrows */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            onPrev();
          }}
          className="absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full 
                     bg-white/90 hover:bg-white transition-all duration-300 
                     transform hover:scale-110 group z-20
                     w-10 h-10 md:w-12 md:h-12 flex items-center justify-center"
          aria-label="Previous image"
        >
          <ChevronLeft className="w-5 h-5 md:w-6 md:h-6 text-gray-900 group-hover:text-blue-600" />
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onNext();
          }}
          className="absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full 
                     bg-white/90 hover:bg-white transition-all duration-300 
                     transform hover:scale-110 group z-20
                     w-10 h-10 md:w-12 md:h-12 flex items-center justify-center"
          aria-label="Next image"
        >
          <ChevronRight className="w-5 h-5 md:w-6 md:h-6 text-gray-900 group-hover:text-blue-600" />
        </button>

        {/* Image counter */}
        <div
          className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-white px-4 py-2 
                        rounded-full text-gray-900 text-sm flex items-center gap-2 shadow-lg"
        >
          <span className="font-medium">{index + 1}</span>
          <span className="text-gray-400">/</span>
          <span className="text-gray-600">{total}</span>
        </div>

        {/* Main image container */}
        <div className="w-full h-full flex items-center justify-center mt-8">
          <div className="relative max-w-[90%] max-h-[80vh] flex items-center justify-center">
            <img
              src={image}
              alt="Full size view"
              className="max-w-full max-h-full object-contain select-none rounded-lg"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Main ImageSlider Component
const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const [dragOffset, setDragOffset] = useState(0);
  const [modalImage, setModalImage] = useState(null);
  const [sliderHeight, setSliderHeight] = useState("auto");

  const autoPlayRef = useRef(null);
  const progressRef = useRef(null);
  const sliderRef = useRef(null);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  const minSwipeDistance = 50;

  // Calculate and update slider height based on viewport
  useEffect(() => {
    const updateHeight = () => {
      const isMobile = window.innerWidth < 768;
      const viewportHeight = window.innerHeight;
      const maxHeight = isMobile ? viewportHeight * 0.6 : 600;
      setSliderHeight(`${maxHeight}px`);
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const nextSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setProgress(0);
    setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    setTimeout(() => setIsTransitioning(false), 500);
  }, [images.length, isTransitioning]);

  const prevSlide = useCallback(() => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setProgress(0);
    setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    setTimeout(() => setIsTransitioning(false), 500);
  }, [images.length, isTransitioning]);

  // Image click handler
  const handleImageClick = (src, index) => {
    if (!isDragging) {
      setModalImage({ src, index });
      setIsAutoPlaying(false);
    }
  };

  // Modal navigation
  const handleModalNext = () => {
    setModalImage((prev) => ({
      src: images[(prev.index + 1) % images.length],
      index: (prev.index + 1) % images.length,
    }));
  };

  const handleModalPrev = () => {
    setModalImage((prev) => ({
      src: images[prev.index === 0 ? images.length - 1 : prev.index - 1],
      index: prev.index === 0 ? images.length - 1 : prev.index - 1,
    }));
  };

  const closeModal = () => {
    setModalImage(null);
    setIsAutoPlaying(true);
  };

  // Touch handlers
  const onTouchStart = (e) => {
    setIsAutoPlaying(false);
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    e.preventDefault();
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    if (Math.abs(distance) > minSwipeDistance) {
      if (distance > 0) {
        nextSlide();
      } else {
        prevSlide();
      }
    }
    setTouchStart(null);
    setTouchEnd(null);
    setIsAutoPlaying(true);
  };

  // Mouse drag handlers
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart(e.clientX);
    setDragOffset(0);
    setIsAutoPlaying(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const offset = e.clientX - dragStart;
    setDragOffset(offset);
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    if (Math.abs(dragOffset) > minSwipeDistance) {
      if (dragOffset > 0) {
        prevSlide();
      } else {
        nextSlide();
      }
    }
    setIsDragging(false);
    setDragOffset(0);
    setIsAutoPlaying(true);
  };

  // Auto-play and progress
  useEffect(() => {
    if (isAutoPlaying) {
      setProgress(0);
      progressRef.current = setInterval(() => {
        setProgress((prev) => (prev >= 100 ? 0 : prev + 0.1));
      }, 10);

      autoPlayRef.current = setInterval(nextSlide, 10000);
    }

    return () => {
      if (progressRef.current) clearInterval(progressRef.current);
      if (autoPlayRef.current) clearInterval(autoPlayRef.current);
    };
  }, [isAutoPlaying, nextSlide]);

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 px-2 md:px-4 py-4">
      <div className="max-w-6xl mx-auto mt-16 md:mt-32">
        <div
          ref={sliderRef}
          className="group relative rounded-xl md:rounded-2xl overflow-hidden shadow-lg bg-white"
          style={{ height: sliderHeight }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {/* Progress Bar */}
          <div className="absolute top-0 left-0 w-full h-1 bg-gray-100 z-20">
            <div
              className="h-full bg-blue-500 transition-all duration-100 ease-linear"
              style={{ width: `${progress}%` }}
            />
          </div>

          {/* Navigation Arrows */}
          <NavigationArrow
            direction="left"
            onClick={prevSlide}
            disabled={isTransitioning}
          />
          <NavigationArrow
            direction="right"
            onClick={nextSlide}
            disabled={isTransitioning}
          />

          {/* Images */}
          <div className="relative w-full h-full">
            {images.map((src, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-all duration-500 ease-out
                  ${
                    currentIndex === index
                      ? "opacity-100 translate-x-0"
                      : "opacity-0 " +
                        (index < currentIndex
                          ? "-translate-x-full"
                          : "translate-x-full")
                  }`}
                style={{
                  transform:
                    currentIndex === index && isDragging
                      ? `translateX(${dragOffset}px)`
                      : undefined,
                }}
              >
                <div
                  className="w-full h-full flex items-center justify-center bg-white cursor-pointer group"
                  onClick={() => handleImageClick(src, index)}
                >
                  <img
                    src={src}
                    alt={`Slide ${index + 1}`}
                    className="max-w-full max-h-full w-auto h-auto object-contain transition-transform group-hover:scale-[1.02]"
                    draggable="false"
                  />
                  <div className="absolute inset-0 bg-black/0 group-hover:bg-black/10 transition-colors flex items-center justify-center">
                    <ZoomIn className="w-8 h-8 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Progress Dots */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-1.5 z-20">
            {images.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  if (!isTransitioning) {
                    setCurrentIndex(index);
                    setProgress(0);
                  }
                }}
                className={`transition-all duration-300 ease-out rounded-full ${
                  currentIndex === index
                    ? "w-6 h-1.5 bg-blue-500"
                    : "w-1.5 h-1.5 bg-gray-300 hover:bg-gray-400"
                }`}
                disabled={isTransitioning}
                aria-label={`Go to slide ${index + 1}`}
                aria-current={currentIndex === index}
              />
            ))}
          </div>
        </div>

        {/* Full Image Modal */}
        {modalImage && (
          <ImageModal
            image={modalImage.src}
            index={modalImage.index}
            total={images.length}
            onClose={closeModal}
            onNext={handleModalNext}
            onPrev={handleModalPrev}
          />
        )}
      </div>
    </div>
  );
};

export default ImageSlider;
