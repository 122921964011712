export const services = [
  {
    id: "structural-health-monitoring",
    title: "Structural Health Monitoring",
    description:
      "Specializing in the assessment of bridges, ships, and airplanes to ensure integrity and safety.",
  },
  {
    id: "construction-noise-survey",
    title: "Construction Noise Survey and Control",
    description:
      "Expert analysis and control of noise pollution in construction zones to minimize impact on communities.",
  },
  {
    id: "metro-highway-noise",
    title: "Metro and Highway Noise Management",
    description:
      "Providing solutions for traffic noise reduction, enhancing urban soundscapes.",
  },
  {
    id: "industrial-noise-control",
    title: "Industrial Noise Control",
    description:
      "Expertise in reducing noise levels in industrial settings, ensuring compliance with safety regulations.",
  },
  {
    id: "product-noise-testing",
    title: "Product Noise Testing and Optimization",
    description:
      "Evaluating and optimizing noise levels in products to meet industry standards.",
  },
  {
    id: "community-noise-surveys",
    title: "Community Noise Surveys",
    description:
      "Conducting surveys to assess noise levels in residential areas and proposing reduction strategies.",
  },
  {
    id: "environmental-impact",
    title: "Environmental Impact Assessment",
    description:
      "Assessing the impact of noise on ecosystems and proposing mitigation measures.",
  },
  {
    id: "public-spaces-noise",
    title: "Noise Reduction in Public Spaces",
    description:
      "Implementing strategies to reduce noise levels in parks, plazas, and recreational areas.",
  },
  {
    id: "healthcare-noise-control",
    title: "Noise Control in Healthcare Facilities",
    description:
      "Minimizing noise disturbance in hospitals, improving patient recovery and staff efficiency.",
  },
  {
    id: "noise-monitoring-systems",
    title: "Noise Monitoring Systems",
    description:
      "Installation of advanced noise monitoring systems for real-time data analysis.",
  },
  {
    id: "noise-barrier-design",
    title: "Noise Barrier Design",
    description:
      "Designing noise barriers to mitigate pollution from highways, railways, and industrial sites.",
  },
  {
    id: "urban-development-noise",
    title: "Urban Development Noise Check",
    description:
      "Assessing noise impact of urban projects and suggesting mitigation measures.",
  },
];
