import React from "react";
import { MdPhoneInTalk, MdEmail, MdLink } from "react-icons/md";
import { Tilt } from "react-tilt";

const ContactCard = ({ icon: Icon, title, contactDetail, href }) => (
  <Tilt
    options={{
      max: 25,
      scale: 1.05,
      speed: 400,
      glare: true,
      "max-glare": 0.5,
    }}
  >
    <div className="text-center p-4 rounded-lg shadow-lg bg-gray-800 hover:bg-blue-700 cursor-pointer transform transition duration-300">
      <Icon className="mx-auto mb-3 text-4xl text-blue-400 hover:text-blue-300" />
      <p className="font-semibold">{title}:</p>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-300 transition-colors duration-300"
      >
        {contactDetail}
      </a>
    </div>
  </Tilt>
);

const ContactInfoSection = () => (
  <section className="bg-gradient-to-r from-blue-800 to-gray-900 text-white py-8 px-6 md:px-8 lg:px-12">
    <div className="text-2xl font-bold mb-8 text-center">Contact Info</div>
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
      <ContactCard
        icon={MdPhoneInTalk}
        title="Mobile"
        contactDetail="+918308839157"
        href="tel:+918308839157"
      />
      <ContactCard
        icon={MdEmail}
        title="Email"
        contactDetail="pawanpingle@gmail.com"
        href="mailto:pawanpingle@gmail.com"
      />
      <ContactCard
        icon={MdLink}
        title="Website"
        contactDetail="nvhconsultancy.com"
        href="http://www.nvhconsultancy.com"
      />
    </div>
    <div className="text-center text-sm mt-10">
      © 2024 - NVH Consultancy | All Rights Reserved
      <br />
      Powered by{" "}
      <a
        href="http://www.techave.in"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-gray-300 transition-colors duration-300"
      >
        TechAve
      </a>
    </div>
  </section>
);

export default ContactInfoSection;
