import React from 'react'
import { Outlet } from 'react-router'
import { ScrollRestoration } from 'react-router-dom';
import './index.css'
import Navbar from './components/Navbar.js';
import ContactInfoSection from './components/ContactInfoSection.js';

const Base = () => {
  let getKey = React.useCallback((location, matches) => {
  let match = matches.find(m => m.handle?.scrollMode)
  if (match?.handle?.scrollMode === "pathname") {
    return location.pathname
  }

  return location.key
}, [])

  
  return (
    < div className='AppContainer'>
    <Navbar />    
    <div className='outlet'>
    <Outlet  />
    </div>
   
    <br />
   

    <ContactInfoSection />
    <ScrollRestoration getKey={getKey} />
    </div>
  )
}

export default Base