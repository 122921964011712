import React from "react";
import Whatwedo from "./Whatwedo";

const Services = () => {
  return (
    <div>
      <Whatwedo />
    </div>
  );
};

export default Services;
