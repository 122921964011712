import React from "react";
import Navbar from "./components/Navbar";
import ImageSlider from "./components/ImageSlider";
import NVHConsultancySection from "./components/NVHConsultancySection"; // Import the new section component
import ContactInfoSection from "./components/ContactInfoSection";
import { ThemeProvider } from "./ThemeContext";
// Import Tailwind CSS file
import "./index.css";

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <ImageSlider />
        <NVHConsultancySection />
      </div>
    </ThemeProvider>
  );
}

export default App;
