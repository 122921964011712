import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"; // Importing icons for menu
import logo from "../assets/nvh-logo.png"; // Your logo import
import "./Navbar.css";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-gray-800 p-3 navbar">
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/">
          <img src={logo} alt="NVH Consultancy Logo" className="h-12 w-auto" />
        </Link>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isMenuOpen ? (
              <AiOutlineClose className="text-white" />
            ) : (
              <AiOutlineMenu className="text-white" />
            )}
          </button>
        </div>
        <div className="md:flex md:items-center md:justify-end hidden">
          {["/", "/about", "/services", "/projects", "/youtubechannel"].map(
            (path, index) => {
              const labels = [
                "Home",
                "About Us",
                "What We Do",
                "Recent Projects",
                "YouTube Channel",
              ];
              return (
                <Link
                  key={index}
                  to={path}
                  className="text-white px-4 py-2 hover:bg-gray-700 transition-colors"
                >
                  {labels[index]}
                </Link>
              );
            }
          )}
        </div>
      </div>
      {/* Animated menu for mobile view */}
      <div
        className={`md:hidden fixed top-0 left-0 h-full bg-gray-800 w-64 z-50 transform transition-transform duration-300 ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col justify-start h-full pt-12">
          {["/", "/about", "/services", "/projects", "/youtubechannel"].map(
            (path, index) => {
              const labels = [
                "Home",
                "About Us",
                "What We Do",
                "Recent Projects",
                "YouTube Channel",
              ];
              return (
                <Link
                  key={index}
                  to={path}
                  className="text-white px-4 py-2 hover:bg-gray-700 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {labels[index]}
                </Link>
              );
            }
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
