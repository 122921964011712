import React from "react";
import Services from "./Services";
import Statistics from "./Statistics";
import { Fade } from "react-awesome-reveal";

const NVHConsultancySection = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center">
      <div className="transition-all duration-500 ease-in-out">
        <div className="text-center px-4 py-16 my-12 max-w-7xl mx-auto space-y-12 bg-gradient-to-r from-blue-50 to-blue-100 shadow-2xl rounded-2xl backdrop-filter backdrop-blur-lg">
          {/* Section Title */}
          <div className="px-4 md:px-8 lg:px-12">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 transition-all duration-500 ease-in-out">
              NVH Consultancy
            </h1>
          </div>

          {/* Introduction and Description */}
          <Fade direction="up" triggerOnce>
            <div className="mb-12 space-y-6 px-2 md:px-8 lg:px-12">
              <h2 className="text-4xl md:text-6xl lg:text-8xl font-extrabold text-gray-800 mb-6 transition-all duration-500 ease-in-out">
                Premier NVH Consultancy Services
              </h2>
              <p className="text-lg md:text-xl lg:text-2xl text-gray-700 leading-relaxed transition-all duration-500 ease-in-out">
                Specializing in advanced noise, vibration, and harshness (NVH)
                solutions, our consultancy is dedicated to improving acoustic
                comfort and performance...
              </p>
            </div>
          </Fade>

          {/* Services Section */}
          <Fade direction="up" delay={150} triggerOnce>
            <div className="transform hover:scale-105 transition-all duration-700 ease-in-out hover:shadow-xl rounded-lg bg-white p-6">
              <Services />
            </div>
          </Fade>

          {/* Statistics Section */}
          <Fade direction="up" delay={300} triggerOnce>
            <div className="transform hover:scale-105 transition-all duration-700 ease-in-out hover:shadow-xl rounded-lg bg-white p-6 mt-6">
              <Statistics />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default NVHConsultancySection;
