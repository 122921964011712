import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaTimes } from "react-icons/fa";
import bearingVibrationsImage from "../../assets/bearing-1.png";
import engineAutoComponentImage from "../../assets/enigine2.png";
import isoNoiseVibrationTestsImage from "../../assets/iso-noise.png";
import nvhDesignAcceptanceImage from "../../assets/nvhdesign1.png";
import nvhSurveyImage from "../../assets/survey.png";
import diagnosticsDefectAnalysisImage from "../../assets/diagnostics.png";
import nvhTrainingImage from "../../assets/nvhtraining1.png";
import machineryVibrationDiagnosticsImage from "../../assets/casestudy6.png";
import bearingVibrationsCardImage from "../../assets/bearing-1.png";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";
import { FaEye } from "react-icons/fa";

// Custom Hook for trapping focus within the modal
const useFocusTrap = (modalRef) => {
  useEffect(() => {
    const focusableModalElements = modalRef.current?.querySelectorAll(
      "a[href], button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])"
    );
    if (!focusableModalElements?.length) return;

    const firstElement = focusableModalElements[0];
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];

    const trapFocus = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          e.preventDefault();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          e.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", trapFocus);
    return () => document.removeEventListener("keydown", trapFocus);
  }, [modalRef]);
};

// ImageModal Component with enhanced UX
const ImageModal = React.memo(({ image, onClose }) => {
  const modalRef = useRef(null);
  useFocusTrap(modalRef);

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
      backdropFilter: "blur(10px) saturate(180%)",
    },
  };

  const modalVariants = {
    hidden: { scale: 0.95, opacity: 0, transition: { duration: 0.25 } },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { delay: 0.1, duration: 0.4, type: "spring", stiffness: 100 },
    },
  };

  const closeButtonVariants = {
    initial: { scale: 1 },
    hover: { scale: 1.1, rotate: 45 },
    tap: { scale: 0.95 },
  };

  return (
    <AnimatePresence>
      {image && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/30 dark:bg-gray-900/60"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={(e) => e.target === e.currentTarget && onClose()}
          role="dialog"
          aria-modal="true"
          ref={modalRef}
        >
          <motion.div
            className="relative w-full max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-xl shadow-2xl overflow-hidden"
            variants={modalVariants}
          >
            <motion.button
              onClick={onClose}
              aria-label="Close modal"
              className="absolute right-5 top-5 z-50 p-2 text-white bg-red-500 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-red-400 transition duration-150 ease-in-out"
              variants={closeButtonVariants}
              initial="initial"
              whileHover="hover"
              whileTap="tap"
            >
              <FaTimes size="24px" />
            </motion.button>
            <motion.img
              src={image}
              alt="Detailed view"
              className="w-full max-h-[75vh] object-contain p-5 lg:p-8 transition-all duration-300 ease-in-out"
              loading="lazy"
              variants={{
                hidden: { scale: 0.9, opacity: 0 },
                visible: { scale: 1, opacity: 1, transition: { delay: 0.5 } },
              }}
              initial="hidden"
              animate="visible"
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
});

// Card Component with dynamic gradients and hover effects
const Card = React.memo(({ title, image, onClick }) => {
  const cardVariants = {
    offscreen: { y: 300, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.3,
        stiffness: 70,
        damping: 15,
      },
    },
  };

  return (
    <motion.div
      className="max-w-sm mx-auto my-8 overflow-hidden rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
      onClick={() => image && onClick(image)}
      variants={cardVariants}
      initial="offscreen"
      animate="onscreen"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      tabIndex={0}
      role="button"
      aria-label={`View larger image of ${title}`}
    >
      {image && (
        <div className="w-full h-52 lg:h-60 overflow-hidden">
          <img src={image} alt={title} className="w-full h-full object-cover" />
        </div>
      )}
      <div className="p-6 space-y-3 bg-gray-400 dark:bg-black/80">
        <h3 className="text-xl md:text-2xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-700 to-blue-500 dark:from-green-300 dark:to-blue-400 hover:text-opacity-100 transition-all duration-300">
          {title}
        </h3>
        <motion.button
          className="py-2 px-6 w-full text-lg rounded-full font-semibold flex items-center justify-center bg-white text-gray-800 dark:bg-gray-900 dark:text-gray-100 hover:bg-gradient-to-r from-blue-700 to-blue-500 dark:hover:from-green-300 dark:hover:to-blue-400 hover:text-white transition duration-300 ease-in-out shadow-md hover:shadow-lg"
          onClick={(e) => {
            e.stopPropagation();
            image && onClick(image);
          }}
          tabIndex={0}
        >
          <FaEye className="mr-2 text-xl" /> View More
        </motion.button>
      </div>
    </motion.div>
  );
});

// WhatWeDo Component with a responsive and interactive UI
const WhatWeDo = () => {
  const [modalImage, setModalImage] = useState(null);
  const openModal = useCallback((image) => setModalImage(image), []);
  const closeModal = useCallback(() => setModalImage(null), []);

  const gradientTextStyle = {
    background: "linear-gradient(90deg, #6ee7b7 0%, #3b82f6 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  };

  // Animation variants
  const textVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.8 } },
  };

  const cardData = [
    { title: "ISO Noise-Vibration Tests", image: isoNoiseVibrationTestsImage },
    {
      title: "NVH Design & Acceptance Criterion",
      image: nvhDesignAcceptanceImage,
    },
    { title: "NVH Survey & Specialized Measurements", image: nvhSurveyImage },
    {
      title: "Diagnostics & Defect Analysis",
      image: diagnosticsDefectAnalysisImage,
    },
    { title: "NVH Training", image: nvhTrainingImage },
    {
      title: "Machinery Vibration Diagnostics",
      image: machineryVibrationDiagnosticsImage,
    },
    { title: "Bearing Vibrations", image: bearingVibrationsCardImage },
    { title: "NVH APPS" },
    {
      title: "Engine & Auto Component Testing",
      image: engineAutoComponentImage,
    },
  ];

  return (
    <div className="container mx-auto px-4 pt-20 pb-28">
      <Transition
        show={true}
        enter="transition-opacity delay-100 duration-700"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="text-center mb-20">
          <motion.h2
            className="text-6xl font-extrabold leading-tight transition duration-700 ease-in-out"
            style={gradientTextStyle}
            variants={textVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            What We Do
          </motion.h2>
        </div>
      </Transition>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-x-8 gap-y-12">
        {cardData.map((card, index) => (
          <Transition
            key={index}
            as="div"
            show={true}
            enter="transition-transform duration-[800ms] ease-out delay-[index*100ms]"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition-opacity duration-500 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Card title={card.title} image={card.image} onClick={openModal} />
          </Transition>
        ))}
      </div>
      <ImageModal image={modalImage} onClose={closeModal} />
    </div>
  );
};

export default WhatWeDo;
