import React, { useState } from "react";
import { motion } from "framer-motion";
import pres from "../../assets/PawanPingle.jpg";

const AboutUs = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Container animation
  const containerVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: 0.2, when: "beforeChildren", staggerChildren: 0.1 },
    },
  };

  // Child animation (for text and image)
  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  // Toggle Dark Mode
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Dynamic styles based on the dark mode state
  const dynamicStyles = {
    backgroundColor: isDarkMode ? "#1A202C" : "white",
    color: isDarkMode ? "white" : "black",
  };

  return (
    <div
      className={`min-h-screen flex items-center justify-center ${
        isDarkMode ? "bg-gray-800" : "bg-gray-50"
      }`}
    >
      <button
        onClick={toggleDarkMode}
        className="absolute top-5 right-5 text-white font-bold py-2 px-4 rounded"
      >
        {isDarkMode ? "Light Mode" : "Dark Mode"}
      </button>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="container mx-auto p-5 lg:p-12 rounded-2xl shadow-2xl max-w-5xl mt-20" // Added mt-12 for moving the card a little down
        style={dynamicStyles}
      >
        <div className="flex flex-col lg:flex-row -mx-4">
          <motion.div
            className="lg:w-1/3 px-4 mb-8 lg:mb-0"
            variants={childVariants}
          >
            <motion.div
              className="rounded-2xl overflow-hidden shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={pres}
                alt="Dr. Pawan Pingle"
                className="w-full h-auto object-cover object-center"
              />
              <div className="p-4">
                <h6
                  className="font-semibold text-lg mb-2"
                  style={{ color: dynamicStyles.color }}
                >
                  Our Founder Dr. Pawan Pingle
                </h6>
              </div>
            </motion.div>
          </motion.div>

          <motion.div className="lg:w-2/3 px-4" variants={childVariants}>
            <div className="pt-10 lg:pt-0">
              <h2
                className="text-3xl lg:text-4xl font-bold leading-tight mb-4"
                style={{ color: dynamicStyles.color }}
              >
                About Dr. Pawan Pingle
              </h2>
              <p
                className="text-lg lg:text-xl mb-8"
                style={{ color: dynamicStyles.color }}
              >
                PhD, MS, BE & Diploma in Mechanical Engineering
              </p>
              <div
                className="space-y-4 prose lg:prose-lg max-w-none"
                style={{ color: dynamicStyles.color }}
              >
                <p>
                  Dr. Pawan Pingle stands at the forefront of Noise & Vibration
                  Engineering, pushing the boundaries of what's possible in
                  product innovation and quality. His journey, fueled by a
                  passion for making a tangible difference in the world through
                  engineering, has led him to become one of the most respected
                  figures in his field.
                </p>
                <ul>
                  <li>
                    Earned a PhD in Mechanical Engineering from the University
                    of Massachusetts, USA, in 2010, focusing on advanced
                    vibration suppression techniques.
                  </li>
                  <li>
                    Has over a decade of experience providing consultancy
                    services to multinational corporations, enhancing product
                    designs for reduced noise and improved user experience.
                  </li>
                  <li>
                    As an adjunct professor and a global speaker, Dr. Pingle has
                    shared his knowledge and insights with aspiring engineers
                    and industry professionals across the globe.
                  </li>
                  <li>
                    He is continually engaged in research and development,
                    aiming to innovate within the fields of Noise and Vibration
                    Engineering, to create more efficient and sustainable
                    technologies.
                  </li>
                </ul>
                <p>
                  Dr. Pingle's vision extends beyond the technical aspects of
                  his work; he is deeply committed to mentoring the next
                  generation of engineers and making advanced engineering
                  solutions accessible to a broader audience. His approach
                  combines rigorous scientific methods with a deep empathy for
                  human needs, driving him to create products that enhance
                  people's lives and the environment.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default AboutUs;
