import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import { clients } from "./ClientsData";
import { services } from "./ServiceData";

const CustomArrow = ({ onClick, isNext }) => (
  <button
    className={`absolute top-1/2 -translate-y-1/2 ${
      isNext ? "-right-6" : "-left-6"
    } w-8 h-8 flex items-center justify-center bg-gray-800/70 rounded-full z-10 hover:bg-gray-800`}
    onClick={onClick}
    aria-label={isNext ? "Next" : "Previous"}
  >
    <svg
      className="w-5 h-5 text-white"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d={isNext ? "M9 5l7 7-7 7" : "M15 19l-7-7 7-7"}
      />
    </svg>
  </button>
);

const Services = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    nextArrow: <CustomArrow isNext={true} />,
    prevArrow: <CustomArrow isNext={false} />,
    pauseOnHover: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const validServices = services.map((service, index) => ({
    ...service,
    id: service.id || `service-${index + 1}`,
  }));

  const validClients = clients.map((client, index) => ({
    ...client,
    id: client.id || `client-${index + 1}`,
  }));

  return (
    <div>
      <div className="text-center px-4 py-16 my-12 max-w-7xl mx-auto relative">
        <h1 className="text-4xl font-bold mb-6">Our Services</h1>
        <div className="slick-container">
          <Slider {...sliderSettings}>
            {validServices.map((service) => (
              <motion.div
                key={service.id}
                className="p-4"
                whileHover={{ scale: 1.05, translateY: -10 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="bg-[#BFD4FF] shadow-xl rounded-xl overflow-hidden h-full flex flex-col">
                  <div className="p-6 flex flex-col justify-between h-full">
                    <h2 className="text-2xl font-semibold mb-2 text-gray-800">
                      {service.title}
                    </h2>
                    <p className="text-base mb-4 text-gray-600 flex-grow">
                      {service.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="bg-white py-8">
        <h2 className="text-3xl font-bold text-center mb-6">Our Clients</h2>
        <div className="flex flex-wrap justify-center items-center mb-8">
          {validClients.map((client) => (
            <img
              key={client.id}
              className="m-2 sm:m-4 w-32 sm:w-48 h-32 sm:h-48 object-contain hover:opacity-75"
              src={client.src}
              alt={client.alt}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
