import React from "react";

const StatisticsCard = ({ number, text }) => (
  <article className="flex-1 px-4 py-2 text-center">
    <h2 className="text-4xl font-bold text-black mb-2">{number}</h2>
    <p className="text-base text-black max-w-xs mx-auto">{text}</p>
  </article>
);

const Statistics = () => {
  return (
    <div className="w-full bg-blue-200 p-8">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-start gap-8">
        <StatisticsCard
          number="10+"
          text="Years of NVH consultancy experience (USA/Europe/India)"
        />
        <StatisticsCard
          number="30+"
          text="Journal paper publications in reputed Journals of Sound & Vibrations"
        />
        <StatisticsCard
          number="10+"
          text="Years of Training and Teaching experience"
        />
      </div>
    </div>
  );
};

export default Statistics;
