import siemensLogo from "../assets/logo-siemens.png";
import rajmaneLogo from "../assets/logo-rajmane.png";
import hondaLogo from "../assets/logo-honda.png";
import exampleLogo1 from "../assets/logo-pratt.png";
import exampleLogo2 from "../assets/logo-nrb.png";
import exampleLogo3 from "../assets/logo-nationalInstruments.png";
import exampleLogo4 from "../assets/logo-massachusetts.png";
import exampleLogo5 from "../assets/logo-mando.png";
import exampleLogo6 from "../assets/logo-lucas.png";
import exampleLogo7 from "../assets/logo-greaves.png";
import exampleLogo8 from "../assets/logo-drdo.png";
import exampleLogo9 from "../assets/logo-definitics.png";
import exampleLogo10 from "../assets/logo-arl.png";
import exampleLogo11 from "../assets/logo-hodek.png";

export const clients = [
  {
    id: "siemens",
    src: siemensLogo,
    alt: "Siemens",
  },
  {
    id: "rajmane",
    src: rajmaneLogo,
    alt: "Rajmane",
  },
  {
    id: "honda",
    src: hondaLogo,
    alt: "Honda",
  },
  {
    id: "pratt-whitney",
    src: exampleLogo1,
    alt: "Pratt & Whitney",
  },
  {
    id: "nrb-bearings",
    src: exampleLogo2,
    alt: "NRB Bearings",
  },
  {
    id: "national-instruments",
    src: exampleLogo3,
    alt: "National Instruments",
  },
  {
    id: "mit",
    src: exampleLogo4,
    alt: "Massachusetts Institute of Technology",
  },
  {
    id: "mando",
    src: exampleLogo5,
    alt: "Mando Corporation",
  },
  {
    id: "lucas-tvs",
    src: exampleLogo6,
    alt: "Lucas-TVS",
  },
  {
    id: "greaves-cotton",
    src: exampleLogo7,
    alt: "Greaves Cotton",
  },
  {
    id: "drdo",
    src: exampleLogo8,
    alt: "DRDO",
  },
  {
    id: "definitics",
    src: exampleLogo9,
    alt: "Definitics Software",
  },
  {
    id: "arl",
    src: exampleLogo10,
    alt: "ARL",
  },
  {
    id: "hodek",
    src: exampleLogo11,
    alt: "Hodek Vibration Technologies",
  },
];
