import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Base from './base';

import { createTheme, ThemeProvider } from '@mui/material';
import AboutUs from './components/About/AboutUs';
import Services from './components/Services/Services';
import Projects from './components/Projects/projects';
import { YouTube } from '@mui/icons-material';
import VideoSlider from './components/Youtube/VideoCarousel';




const THEME = createTheme({
  typography: {
   "fontFamily": ` 'Lato', sans-serif`,
   
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <Base />,
   // errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <App /> },
      
      { path: "about", element: <AboutUs /> },
        {path: "services", element: <Services />},
        {path: "projects", element: <Projects />},
        {path: "youtubechannel", element: <VideoSlider />},
      //  {path: "contact", element: <Contactus />},
      //  {path: "gallery", element: <Gallery />},
      //  {path: 'doctorscorner', element: <DoctorCorner />},
      //  {path: 'patientcare', element: <Patientcare />},
      //  {path: 'memberspage', element: <Memberspage />},
      //  {path: 'profile', element: <ProtectedRoute component={Profile } />},
      //  {path: 'directory', element: <ProtectedRoute component={Directory} />},
      //  {path: 'news', element: <News />},
      //  {path: 'academic', element: <CityBranchActivities />},
      //  {path: 'nonacademic', element: <NonAcademic />},
      //  {path: 'COLS', element: <Cols />},

      //  {path: 'AddEvents', element: <ProtectedRoute component={AddEvent} />}
    ],
  },
]);

/* root.render(
  <ThemeProvider theme={THEME}>
    <React.StrictMode>
     <SnackbarProvider
      autoHideDuration={2000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      maxSnack={3}>

      <Provider  store={store}> <RouterProvider router={router} /></Provider>
   
  </SnackbarProvider>
  </React.StrictMode>
  </ThemeProvider>
); */

if (root.hasChildNodes) {
  root.hydrate(<ThemeProvider theme={THEME}>
    <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
  </ThemeProvider>, root);
} else {
  root.render(<ThemeProvider theme={THEME}>
    <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
  </ThemeProvider>, root);
}




/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
 */
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();