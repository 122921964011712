import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSpring, animated } from "react-spring";
import { Tilt } from "react-tilt";
import "react-image-lightbox/style.css";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

// Local images import
import noiseImage from "../../assets/noisevibra.png";
import vibrationImage from "../../assets/engines.png";
import caeImage from "../../assets/cae.png";
import industrialImage from "../../assets/quality.png";
import softwaredevelopmentImage from "../../assets/nvh_soft.png";
import dataImage from "../../assets/data.png";
import virtualRealityImage from "../../assets/nvh_real.png";
import machineLearningImage from "../../assets/ml_nvh.png";

// Sample projects data with local images
const sampleProjects = [
  {
    title: "Noise Testing and Analysis",
    description: "Comprehensive noise testing and analysis.",
    imageURL: noiseImage,
    date: "2023-01-15",
  },
  {
    title: "Vibration Testing of Engines",
    description: "Vibration testing on engine desing and optimizations.",
    imageURL: vibrationImage,
    date: "2023-05-20",
  },
  {
    title: "CAE Design Optimizations",
    description: "CAE design optimizations for enhanced product performance.",
    imageURL: caeImage,
    date: "2023-09-10",
  },
  {
    title: "Quality Control Checks Using NVH",
    description: "Quality control checks using NVH standards.",
    imageURL: industrialImage,
    date: "2023-11-30",
  },
  {
    title: "NVH Software Development",
    description: "Software development for advanced NVH analysis.",
    imageURL: softwaredevelopmentImage,
    date: "2024-02-15",
  },
  {
    title: "Data Analysis Dashboard",
    description: "Dashboard development for complex dataset analysis.",
    imageURL: dataImage,
    date: "2024-03-10",
  },
  {
    title: "NVH Analysis in Virtual Reality",
    description: "Virtual reality simulation for NVH analysis and design.",
    imageURL: virtualRealityImage,
    date: "2024-03-10",
  },
  {
    title: "Machine Learning for NVH Data Analysis",
    description: "Machine learning for predictive NVH analysis.",
    imageURL: machineLearningImage,
    date: "2024-05-25",
  },
];
const ProjectCard = ({ project, onClick }) => {
  const [props, set] = useSpring(() => ({
    to: { scale: 1.03, opacity: 1, borderRadius: 10 },
    from: { scale: 1, opacity: 0.95, borderRadius: 8 },
    config: { mass: 1, tension: 200, friction: 18 },
  }));

  return (
    <Tilt
      className="Tilt responsive-card mx-auto cursor-pointer mt-10"
      options={{ max: 10, scale: 1.02, speed: 500, transition: true }}
      style={{ width: "100%", maxWidth: "340px" }}
    >
      <animated.div
        className="card-base relative overflow-hidden shadow-lg transition-all duration-500"
        style={{
          transform: props.scale.interpolate((s) => `scale(${s})`),
          opacity: props.opacity,
          borderRadius: props.borderRadius.interpolate((br) => `${br}px`),
          backgroundImage: "linear-gradient(to top right, #E0EAFC, #CFDEF3)",
        }}
        onMouseEnter={() => set({ scale: 1.05, opacity: 1, borderRadius: 12 })}
        onMouseLeave={() =>
          set({ scale: 1.03, opacity: 0.95, borderRadius: 10 })
        }
        onClick={() => onClick(project)}
        tabIndex="0"
      >
        <div className="h-64 relative">
          <animated.img
            className="object-cover w-full h-full transition-transform duration-700 hover:scale-110"
            src={project.imageURL}
            alt={project.title}
          />
        </div>

        <div className="p-6">
          <animated.h5
            className="text-xl md:text-2xl font-bold mb-2 text-gray-800 hover:text-gray-600 transition-colors duration-300"
            style={{ transform: props.scale.interpolate((s) => `scale(${s})`) }}
          >
            {/* Conditionally render the title based on screen size */}
            <span className="block sm:hidden">{project.title}</span>{" "}
            {/* Visible on mobile */}
            <span className="hidden sm:block">{project.title}</span>{" "}
            {/* Visible on desktop and up */}
          </animated.h5>
          <p className="text-gray-600 text-sm md:text-base leading-relaxed">
            {project.description}
          </p>
        </div>
      </animated.div>
    </Tilt>
  );
};

const ProjectModal = ({ project, onClose, isDarkMode }) => {
  const handleBackdropClick = (event) => {
    if (event.currentTarget === event.target) {
      onClose(false);
    }
  };

  // Define separate animation variants for each modal section
  const modalSectionVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 0.4 } },
  };

  // Dynamic styles for dark mode
  const modalStyle = isDarkMode
    ? "bg-gray-800 text-white"
    : "bg-white text-gray-800";

  const buttonStyle = isDarkMode
    ? "bg-indigo-600 hover:bg-indigo-700"
    : "bg-blue-600 hover:bg-blue-700";

  return (
    <AnimatePresence>
      <motion.div
        className={`fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50 ${
          isDarkMode ? "bg-opacity-80" : "bg-opacity-70"
        }`}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={handleBackdropClick}
      >
        <motion.div
          className={`p-6 rounded-lg shadow-xl max-w-3xl w-full m-4 ${modalStyle}`}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <motion.h2
            variants={modalSectionVariants}
            className="text-3xl font-semibold mb-3"
          >
            {project.title}
          </motion.h2>
          <motion.p variants={modalSectionVariants} className="my-4">
            {project.description}
          </motion.p>
          <motion.img
            variants={modalSectionVariants}
            src={project.imageURL}
            alt={project.title}
            className="my-4 max-h-96 w-full object-contain rounded-lg shadow-sm hover:shadow-md"
            style={{ cursor: "pointer" }}
          />
          <motion.div
            className="flex justify-end mt-4"
            variants={modalSectionVariants}
          >
            <motion.button
              className={`py-2 px-5 font-medium rounded-md text-white ${buttonStyle} focus:outline-none focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-150`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => onClose(false)}
            >
              Close
            </motion.button>
          </motion.div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <motion.div
      className="min-h-screen pt-20 pb-10 overflow-hidden"
      style={{ backgroundImage: "linear-gradient(to right, #FAFAFA, #E3F2FD)" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {sampleProjects.map((project, index) => (
            <motion.div
              key={index}
              data-aos="fade-up"
              data-aos-delay={`${index * 100}`}
              layout
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <ProjectCard
                project={project}
                onClick={() => handleProjectClick(project)}
              />
            </motion.div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedProject && (
          <ProjectModal
            project={selectedProject}
            onClose={() => setSelectedProject(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Projects;
