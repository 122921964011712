import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom arrow components for the slider
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} customArrowClass`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} customArrowClass`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const VideoSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
      {
        breakpoint: 640,
        settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 },
      },
    ],
  };

  const videos = [
    "ityYOFaCiPA",
    "G_SKh9xvbF0",
    "iSC7MlNBaMY",
    "VI2NX61NXaQ",
    "gJmMp4UI2A0",
    "NH1-YLaEZeg",
    "OK1eejBkcvM",
  ];

  return (
    <>
      <style>
        {`
          .customArrowClass {
            z-index: 20;
            background: #ffffff;
            border-radius: 50%;
          }

          .custom-video-frame {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* 16:9 Aspect Ratio */
          }

          @media (min-width: 768px) {
            .custom-video-frame {
              padding-top: 80%; /* Custom aspect ratio for increased height */
            }
          }
        `}
      </style>

      <div className="max-w-full overflow-hidden pt-20 pb-6">
        <h2 className="text-center text-2xl mb-4 font-semibold">
          <a
            className="hover:text-primary-600 transition-colors duration-300 no-underline text-primary-500"
            href="https://www.youtube.com/channel/UCqPkhL4hXE6zk9q8MTd5-2Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            Subscribe to our YouTube channel
          </a>
        </h2>

        <Slider {...settings}>
          {videos.map((id, index) => (
            <div key={index} className="px-2 md:px-4">
              <div className="custom-video-frame overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
                <iframe
                  className="w-full h-full absolute top-0 left-0"
                  src={`https://www.youtube-nocookie.com/embed/${id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={`video${index + 1}`}
                ></iframe>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default VideoSlider;
